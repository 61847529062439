import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../components/header";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import JobInfo from "../components/jobInfo";
import Applicants from "../components/applicants";
import ShareIcon from "@mui/icons-material/Share";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useSelector } from "react-redux";
import Createjobdrawer from "../components/createjobdrawer";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import RecommendedTab from "../components/recommendedTab";
import Shortlisted from "../components/Shortlisted";
import Comments from "../components/Comments";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import VerifiedIcon from "@mui/icons-material/Verified";
import DialogComponent from "../components/DialogComponent";
// const baseURL = process.env.REACT_APP_BASEURL;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(1),
}));

const jobStatusArray = ["Closed", "Full", "Cancelled", "On Hold", "Active"];

const JobDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(() => {
    // Check if there is a stored active tab in localStorage
    const storedValue = localStorage.getItem("jobdetailsTab");
    return storedValue ? parseInt(storedValue, 10) : 1;
  });
  const [link] = useState("https://example.com");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info"); // could be "success", "info", "warning", or "error"
  const [isEdit, setIsEdit] = useState("");
  const [jobStatus, setJobStatus] = useState(false);
  const anchorRef = useRef(null);
  const [selectedJobStatus, setSelectedJobStatus] = useState(4);
  const InActiveStatus = ["Cancelled", "Closed", "Full"];
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  // const [jobStatusArray,setJobStatusAaray] = useState([])
  const [singleJobStatus, setSingleJobStatus] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [datemodified, setdatemodified] = useState("");
  const [questionrequired, setquestionrequired] = useState("");
  let url = "/jobeze.com/jobDetail";

  const [isSuccess, setIsSuccess] = useState(false);
  const [refreshComments, setRefreshComments] = useState(false);

  //  console.log("id",id )
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Store the active tab in localStorage when it changes
    localStorage.setItem("jobdetailsTab", value.toString());
  }, [value]);

  // console.log("himanhsuuuuuuuuuuuuuuuuuu",value)
  const handleSnackbarOpen = (message) => {
    console.log("Snackbar message:", message);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`https://jobeze.com/jobDetail/${id}`);
      setSnackbarMessage("Copied");
      setAlertSeverity("success");
      setSnackbarOpen(true);

      // Close the Snackbar after a delay (e.g., 3 seconds)
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 1500); // Adjust the delay as needed
    } catch (err) {
      setSnackbarMessage("Failed to copy the link.");
      setAlertSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleClick = () => {
  //     console.info(`You clicked ${options[selectedJobStatus]}`);
  // };

  const handleMenuItemClick = (event, index) => {
    setSelectedJobStatus(index);
    setSingleJobStatus(event);
    setJobStatus(false);
    axios
      .post(
        `change-job-status`,
        {
          status: event,
          job_id: id,
          original_date_modified: datemodified,
        },
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          handleSnackbarOpen("Job Status Changed Successfully");
        }
        setTimeout(() => {
          handleSnackbarClose();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        handleSnackbarOpen(err.response.data.error);
      });
  };

  const handleToggle = () => {
    setJobStatus((prevOpen) => !prevOpen);
  };
  const handleEdit = () => {
    const flag = 1;
    navigate(`/Postjob?edit=${flag}&JobId=${id}`);
  };

  const handleJobStatusClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setJobStatus(false);
  };

  // const toggleDrawer = (open) => () => {
  //   setDrawerOpen(open);
  //   setIsEdit(false);
  // };

  const [jobinfo, setJoninfo] = useState([]);
  const [approved, setIsapproved] = useState(true);
  const [approvedStatus, setApprovedStatus] = useState(0);
  const [open, setOpen] = useState(false);
  const [openA, setOpenA] = useState(false);
  const [reason, setReason] = useState("");
  const [remark, setRemark] = useState("");
  const getToken = useSelector((state) => state.user.token);
  useEffect(() => {
    axios
      .get(`job-info/${id}`, {
        headers: {
          authorization: getToken ? getToken : localStorage.getItem("token"),
        },
      })
      .then((response) => {
        // console.log("response",response.data.message)
        setJoninfo(response.data.message.jobInfoResult[0]);
        setApprovedStatus(response.data.message.jobInfoResult[0].isApproved);
        setdatemodified(response.data.message.jobInfoResult[0].date_modified);
        setquestionrequired(
          response.data.message.jobInfoResult[0].questionnaire_required
        );

        const Applied = response.data.message.hasAnyoneApplied;
        // console.log("Applied",Applied)
        const st = response.data.message.jobInfoResult[0].status;
        const index = jobStatusArray.indexOf(st);
        setSelectedJobStatus(index);
      })
      .catch((err) => {});
  }, [isDrawerOpen || approved]);

  const beforeDate = new Date(`${jobinfo?.date_created}`);
  const today = new Date();
  const diffTime = Math.abs(today - beforeDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const dateTimeString = jobinfo?.date_created;
  const backenddate = dateTimeString && dateTimeString.split("T")[0];

  const todays = new Date();
  const formattedDate = todays.toISOString().split("T")[0];

  let displayText;

  if (formattedDate === backenddate) {
    displayText = "Today";
  } else {
    displayText = `${diffDays} day${diffDays !== 1 ? "s" : ""} ago`;
  }
  const handleApprove = async () => {
    // const remarkValue = remark?.trim()
    //   ? `${localStorage.getItem("email_id")} : ${remark};`
    //   : null;

    const response = await axios.post(
      `/api/v1/job-approved`,
      {
        jobId: id,
        remark, // Send null if remark is empty
        // remark: remarkValue, // Send null if remark is empty
      },
      {
        headers: {
          authorization: getToken ? getToken : localStorage.getItem("token"),
        },
      }
    );

    if (response?.data?.success) {
      handleSnackbarOpen("Job Approved Successfully");
      setIsapproved(false);
      setApprovedStatus(1);
      setRefreshComments((prev) => !prev);
    } else {
      handleSnackbarOpen("Something went wrong");
    }
    setOpenA(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpenA = () => {
    setOpenA(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseA = () => {
    setOpenA(false);
  };

  const handleConfirmReject = async () => {
    const response = await axios.post(
      `/api/v1/job-rejected`,
      {
        jobId: id,
        reason,
        // reason: `${localStorage.getItem("email_id")} : ${reason};`,
      },
      {
        headers: {
          authorization: getToken ? getToken : localStorage.getItem("token"),
        },
      }
    );
    if (response?.data?.success) {
      handleSnackbarOpen("Job Order Rejected");
      setIsapproved(true);
      setApprovedStatus(-1);
      setRefreshComments((prev) => !prev);
    } else {
      handleSnackbarOpen("Something went wrong");
    }
    setOpen(false);
  };

  console.log("value", value);

  return (
    <div>
      <Header />
      {/* <Createjobdrawer  isDrawerOpen={isDrawerOpen} setIsSuccess={setIsSuccess}
                   setDrawerOpen={setDrawerOpen} toggleDrawer={toggleDrawer} isEdit={isEdit} setIsEdit={setIsEdit}/> */}
      <Container maxWidth="xl" sx={{ px: { xs: 1, sm: 2, md: 3, lg: 8 } }}>
        <Box sx={{ flexGrow: 1, mt: 4 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Item>
                {console.log("jobinfo", jobinfo)}
                <img
                  // src={jobinfo?.logo}
                  src={jobinfo?.logo}
                  style={{ width: "72px", height: "72px" }}
                  alt="Logo"
                />
              </Item>
              <Item>
                <Typography
                  variant="h6"
                  align="left"
                  textTransform={"capitalize"}
                  sx={{ wordBreak: "break-word" }}
                >
                  {jobinfo?.title ? jobinfo?.title : "NA"}
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  fontWeight={700}
                  textTransform={"capitalize"}
                  sx={{ wordBreak: "break-word" }}
                >
                  {jobinfo?.title ? jobinfo?.name : "NA"}
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  textTransform={"capitalize"}
                >
                  {" "}
                  {jobinfo?.city ? jobinfo?.city : "NA"},{" "}
                  {jobinfo?.state ? jobinfo?.state : "NA"}
                </Typography>
                <Typography variant="body2" align="left">
                  Posted {displayText}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Typography
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                variant="h3"
                component="h1"
              >
                {/* <EditIcon   sx={{cursor : 'pointer' , color: 'rgba(0, 0, 0, 0.6)'}} 
                                onClick={handleEdit}/> */}
                <ShareIcon
                  onClick={copyToClipboard}
                  sx={{ color: "rgba(0, 0, 0, 0.6)", mx: 2, cursor: "pointer" }}
                />
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={4000}
                  onClose={handleSnackbarClose}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <Alert
                    onClose={handleSnackbarClose}
                    severity={alertSeverity}
                    variant="filled"
                  >
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
                {approvedStatus === 0 ? (
                  <div>
                    <Button variant="contained" onClick={handleOpenA}>
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleOpen}
                      sx={{ mx: 2 }}
                    >
                      Reject
                    </Button>
                  </div>
                ) : (
                  <div>
                    {approvedStatus === -1 ? (
                      <Chip label="Rejected" color="error" />
                    ) : (
                      <Chip label="Approved" color="success" />
                    )}
                  </div>
                )}
                {/* {jobinfo?.isApproved === 0 ? (
                  <Button variant="contained" onClick={handleApprove}>
                    Approve
                  </Button>
                ) : (
                  <Button variant="outlined" disabled>
                    Approved
                  </Button>
                )}
                {jobinfo?.isApproved}

                {jobinfo?.isApproved === 0 ? (
                  <Button variant="contained" color="error" onClick={handleOpen} sx={{ mx: 2 }}>
                    Reject
                  </Button>
                ) : (
                  <Button variant="outlined" disabled sx={{ mx: 2 }}>
                    Rejected
                  </Button>
                )} */}
                <DialogComponent
                  open={open}
                  onClose={handleClose}
                  title="Reject"
                  description="Are you sure you want to reject?"
                  label="Reason"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  onConfirm={handleConfirmReject}
                  confirmText="Reject"
                  confirmColor="error"
                  icon={WarningIcon}
                  disableConfirm={!reason.trim()} // Disable button if reason is empty
                  showRequired={true} // Show asterisk for required fields
                />

                <DialogComponent
                  open={openA}
                  onClose={handleCloseA}
                  title="Approve"
                  description="Are you sure you want to approve?"
                  label="Remarks"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  onConfirm={handleApprove}
                  confirmText="Approve"
                  confirmColor="success"
                  icon={VerifiedIcon}
                  disableConfirm={false} // Button always enabled for approve
                  showRequired={false} // Hide asterisk for optional fields
                />

                {/* <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                                    <Button size='small' variant='contained'>{jobStatusArray[selectedJobStatus]}</Button>
                                    <Button
                                        size="small"
                                        aria-controls={jobStatus ? 'split-button-menu' : undefined}
                                        aria-expanded={jobStatus ? 'true' : undefined}
                                        aria-label="select merge strategy"
                                        aria-haspopup="menu"
                                        onClick={handleToggle}
                                    >
                                        <ArrowDropDownIcon />
                                    </Button>
                                </ButtonGroup> */}
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={jobStatus}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleJobStatusClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {jobStatusArray.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === selectedJobStatus}
                                onClick={() =>
                                  handleMenuItemClick(option, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              textColor={value === 2 ? "secondary" : "primary"}
              indicatorColor={value === 2 ? "secondary" : "primary"}
              onChange={handleChange}
              aria-label="icon label tabs example"
            >
              <Tab
                label="Job Info"
                sx={{ letterSpacing: "1.25px", px: 4 }}
                {...a11yProps(0)}
              />
              {/* <Tab
                label="Shortlisted"
                sx={{ letterSpacing: "1.25px", px: 4 }}
                {...a11yProps(1)}
              /> */}
              <Tab
                label="Applicants"
                sx={{ letterSpacing: "1.25px", px: 4 }}
                {...a11yProps(1)}
              />
              <Tab
                label="Notes"
                sx={{ letterSpacing: "1.25px", px: 4 }}
                {...a11yProps(4)}
              />
              {/* <Tab
                icon={<AutoAwesomeIcon fontSize="small" />}
                iconPosition="start"
                label="AI Recommendations"
                sx={{ letterSpacing: "1.25px", px: 2 }}
                {...a11yProps(3)}
              /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <JobInfo isDrawerOpen={isDrawerOpen} id={id} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Shortlisted questionrequired={questionrequired} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <Applicants
              status={jobStatusArray[selectedJobStatus]}
              questionrequired={questionrequired}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={3}>
            <RecommendedTab
              status={jobStatusArray[selectedJobStatus]}
              id={id}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Comments id={id} refresh={refreshComments}></Comments>
          </CustomTabPanel>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={
              snackbarMessage === "Job Approved Successfully"
                ? "success"
                : "error"
            }
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default JobDetails;
