import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grow,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import SearchIcon from "@mui/icons-material/Search";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddCandidateDrawer from "./addCandidateDrawer";
import CandidateDetails from "./candidateDetails";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { generate_filter_condition } from "../utiles/filter";
import _debounce from "lodash/debounce";
import { onGlobalFliterFunc } from "../utiles/onFilterChange";

// const baseURL = process.env.REACT_APP_BASEURL;
const statusColors = {
  Applied: "primary",
  "Employer Applied": "primary",
  Shortlisted: "success",
  "Tech Evaluation": "warning",
  "Manager Evaluation": "info",
  "HR Round": "info",
  "Offer Released": "success",
  Withdrawn: "error",
  Rejected: "error",
};

const verifiedColors = {
  1: "success",
  0: "error",
};
const columns = [
  // {
  //   field: "id",
  //   headerName: "ID",
  //   width: 90,
  // },
  {
    field: "fullname",
    headerName: "Full Name",
    filterable: false,
    width: 250,
    renderCell: (params) => (
      <Typography
        variant="body2"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        title={params.row.fullname}
      >
        {" "}
        {params.row.fullname || "NA"}{" "}
      </Typography>
    ),
  },
  {
    field: "entered_by",
    headerName: "Created By",
    width: 220,
    filterOperators: getGridStringOperators().filter(
      (operator) =>
        operator.value === "contains" ||
        operator.value === "equals" ||
        operator.value === "isAnyOf"
    ),
  },
  {
    field: "current_location",
    headerName: "Location",
    filterable: false,
    width: 220,
    renderCell: (params) => (
      <Typography
        variant="body2"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        title={params.row.current_location}
      >
        {params.row.current_location || "NA"}
      </Typography>
    ),
  },
  {
    field: "date_created",
    headerName: "Shortlisted On",
    filterable: false,
    width: 225,
    renderCell: (params) =>
      new Date(params.row.date_created).toLocaleDateString("en-US"),
  },

  {
    field: "isVerified",
    headerName: "Verfication Status",
    filterable: false,
    width: 225,
    renderCell: (params) => (
      <Typography>
        <Chip
          size="small"
          label={params.row.isVerified ? "Verified" : "Unverified"}
          variant="outlined"
          color={verifiedColors[params.row.isVerified] || "default"}
        />
      </Typography>
    ),
  },

  {
    field: "last_active",
    headerName: "Last Active On",
    filterable: false,
    width: 150,
    // sortable: false,
    renderCell: (params) => (
      <Typography
        title={params.row.last_active}
        variant="body2"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {params.row.last_active || "NA"}
      </Typography>
    ),
  },
];

const rows = [
  {
    id: 1,
    title: "Job Title",
    type: "Job Type",
    status: "Status",
    date_created: "Created",
    count: "Application",
    count2: "Last Activity",
  },
  {
    id: 2,
    title: "Job Title",
    type: "Job Type",
    status: "Status",
    date_created: "Created",
    count: "Application",
    count2: "Last Activity",
  },
  {
    id: 3,
    title: "Job Title",
    type: "Job Type",
    status: "Status",
    date_created: "Created",
    count: "Application",
    count2: "Last Activity",
  },
  {
    id: 4,
    title: "Job Title",
    type: "Job Type",
    status: "Status",
    date_created: "Created",
    count: "Application",
    count2: "Last Activity",
  },
  {
    id: 5,
    title: "Job Title",
    type: "Job Type",
    status: "Status",
    date_created: "Created",
    count: "Application",
    count2: "Last Activity",
  },
  {
    id: 6,
    title: "Job Title",
    type: "Job Type",
    status: "Status",
    date_created: "Created",
    count: "Application",
    count2: "Last Activity",
  },
];

const Applicants = (props) => {
  const { id } = useParams();
  const [candidatedrawerflag, setcandidatedrawerflag] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [applicants, setApplicantsdata] = useState([]);
  const getToken = useSelector((state) => state.user.token);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [status, setstatus] = useState([]);
  const [isApplicanOpen, setApplicanOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [candidateid, setCandidateId] = useState([]);
  const [checkboxSelected, setcheckboxSelected] = useState(true);
  const [verifiedstatus, setverifiedstatus] = useState("");
  const [refreshagain, setrefreshagain] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
  const InActiveStatus = ["Cancelled", "Closed", "Full"];
  const [opennew, setOpennew] = React.useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 18,
  });
  const [rerenderKey, setRerenderKey] = useState(0);
  const [searchTermPrev, setSearchTermPrev] = useState("");
  const [queryOptions, setQueryOptions] = useState({});
  const [filterOptions, filtersetQueryOptions] = React.useState({
    filterModel: null,
  });
  const [datemodified, setdatemodified] = useState("");
  const [candidateIdDate, setCandidateIdDate] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  // const questionsrequired = props.questionrequired

  const handleSortModelChange = React.useCallback((sortModel) => {
    // Here you save the data you need from the sort model
    // console.log('mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm', sortModel, "2222222");
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  // const onFilterChange = React.useCallback((filterModel) => {
  //   // Here you save the data you need from the filter model
  //   setQueryOptions({ filterModel: { ...filterModel } });
  // })

  // console.log("filterOptions",filterOptions.filterCondition)

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // console.log("********************************",props.questionrequired)
  // console.log("datemodified",datemodified)
  const handleSnackbarOpen = (message) => {
    // console.log("Snackbar message:", message);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // const onFilterChange = React.useCallback((filterModel) => {
  //   console.log("*******",filterModel)
  //   //console.log("*******",value===undefined)

  // //  if(!(filterModel?.items[0].value===undefined)){

  //   if (filterModel?.items && filterModel?.items.length > 0) {
  //     const { field, operator, value } = filterModel?.items[0];

  //     if(operator === "isAnyOf" &&   Array.isArray(value) && value?.length> 0){
  //       let filterCondition = generate_filter_condition(field, operator, value);
  //       filtersetQueryOptions({ filterModel: { ...filterModel }, filterCondition });
  //     }
  //       else if(!(value===undefined) &&  !(Array.isArray(value))){
  //         console.log("hereeeeeeeeeee")
  //     //if (value?.length > 2 || (operator === "isAnyOf" && value?.length > 0)) { // Check if the length of the value is not greater than 3
  //       let filterCondition = generate_filter_condition(field, operator, value);
  //       filtersetQueryOptions({ filterModel: { ...filterModel }, filterCondition });
  //       }

  //    // } else {
  //       // Do something if the length of the value is greater than 3
  //     //}
  //    else {
  //     console.log("elseeee")
  //     filtersetQueryOptions({ filterModel: { ...filterModel }, filterCondition: null });
  //   }
  // }
  //  // }
  // }, []);

  const onFilterChange = React.useCallback((filterModel) => {
    // if (filterModel?.items && filterModel.items.length > 0) {
    //   const { field, operator, value } = filterModel.items[0];
    //   if (value?.length > 2 || (operator === "isAnyOf" && value?.length > 0)) {
    //     // Check if the length of the value is not greater than 3
    //     const filterCondition = generate_filter_condition(
    //       field,
    //       operator,
    //       value
    //     );
    //     filtersetQueryOptions({
    //       filterModel: { ...filterModel },
    //       filterCondition,
    //     });
    //   } else if (
    //     value?.length === 0 ||
    //     (operator === "isAnyOf" && value?.length === 0)
    //   ) {
    //     filtersetQueryOptions({
    //       filterModel: { ...filterModel },
    //       filterCondition: null,
    //     });
    //   } else {
    //     // Do something if the length of the value is greater than 3
    //   }
    // } else {
    //   filtersetQueryOptions({
    //     filterModel: { ...filterModel },
    //     filterCondition: null,
    //   });
    // }

    const respFilterData = onGlobalFliterFunc(filterModel,`and concat(u.first_name, ' ', u.last_name)`);
    if (respFilterData) {
      filtersetQueryOptions(respFilterData);
    }
  }, []);

  useEffect(() => {
    setShowLoader(true);
    let payload = {
      jobId: id,
      isShortlisted: 0,
      filterCriteria: filterOptions?.filterCondition
        ? filterOptions?.filterCondition
        : "",
    };
    if (queryOptions.sortModel && queryOptions.sortModel.length > 0) {
      payload.sortField = queryOptions?.sortModel[0].field;
      payload.sortBy = queryOptions?.sortModel[0].sort;
    }

    // if(filterOptions && filterOptions.filterCondition) {
    //   payload.filterCriteria = filterOptions.filterCondition
    // }

    axios
      .post(
        `job-applicants/${
          searchTermPrev && searchTermPrev !== searchTerm
            ? 1
            : paginationModel.page + 1
        }${searchTerm ? "?search=" + searchTerm : ""}`,
        payload,
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        const filteredApplicants = response.data.message.data.filter(
          (applicant) => applicant.id !== null
        );
        setApplicantsdata(filteredApplicants);
        setShowLoader(false);
        setSearchTermPrev(searchTerm);
        setTotalCount(response.data.message.page);
      })
      .catch((err) => {});
  }, [
    isDrawerOpen,
    open,
    refreshagain,
    isDialogOpen,
    paginationModel,
    queryOptions,
    searchTerm,
    isApplicanOpen,
    filterOptions,
  ]);

  // useEffect(() => {
  //   axios
  //     .get(`candidate/job-status`, {
  //       headers: {
  //         authorization: getToken ? getToken : localStorage.getItem("token"),
  //       },
  //     })
  //     .then((response) => {
  //       setstatus(response.data.message);
  //       // console.log("hello", response.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const HandleRowClick = (params, event) => {
    // console.log("fatima",params.row);
    setverifiedstatus(params.row.isVerified);
    setSelectedRowData(params.row);
    setApplicanOpen(false);
  };

  const filteredApplicants = applicants.filter((row) =>
    Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleSelection = (params, event) => {
    // console.log("1111111111111111111111", params)
    const rowData = filteredApplicants.filter((e) => params.includes(e.id));
    const newdata = rowData.map((e) => {
      return {
        id: e.id,
        date_modified: e.date_modified,
      };
    });
    // console.log(rowData);
    // console.log("newdata")
    setcheckboxSelected(true);
    setIsCheckboxSelected(!!params.length);
    setCandidateId(params);
    setCandidateIdDate(newdata);
  };

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`, "test");
  };
  const saveCommentAndStatus = () => {
    // console.log("selectedStatus", selectedStatus, selectedIndex);
    axios
      .post(
        `change-candidate-job-status`,
        {
          candidate_ids: candidateIdDate,
          job_id: id,
          status: selectedStatus,
          comment: comment,
        },
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          handleSnackbarOpen("Candidate Status Changed Successfully");
          closeDialog();
        }
      })
      .catch((err) => {
        handleSnackbarOpen(err.response.data.error);
        closeDialog();
      });
  };

  const handleMenuItemClick = (event, index, item) => {
    setSelectedIndex(index);
    openDialog();
    setSelectedStatus(item);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleOnClose = () => {
    setApplicanOpen(false);
    setOpennew((prev) => !prev);
  };

  const options = [
    "Create a merge commit",
    "Squash and merge",
    "Rebase and merge",
  ];
  // console.log("feukfuke", selectedIndex);

  // const checkDisabled = () => {
  //   const newArr = filteredApplicants.filter(e => candidateid.includes(e.id));
  //   const isFound = newArr.filter((e) => e.status !== "Withdrawn");
  //   return isFound.length > 0 ? false : true;
  // }

  return (
    <div>
      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          sx={{ width: "25ch" }}
          type="search"
          placeholder="Search..."
          id="standard-search"
          variant="standard"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          {/* <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
              disabled={!isCheckboxSelected || props.status === "Closed" || props.status === "Cancelled" || props.status === "Full"}
            >
              <Button size="small" variant="outlined" onClick={handleClick}>
                Status{" "}
              </Button>
              <Button
                size="small"
                variant="outlined"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup> */}
          {/* <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
  
                        {status.map((option, index) => (
                          <MenuItem
                            key={option.status}
                            value={option.status}
                            disabled={checkDisabled()}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index, option.status)
                            }
                          >
                            {option.status}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper> */}

          {/* Dialog box open here  */}

          {/* <Dialog open={isDialogOpen} fullWidth>
              <DialogTitle>Change Status</DialogTitle>
              <Divider />
              <DialogContent>
                <Typography variant="body1" className="fw-500" gutterBottom >Add Comment</Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Comment"
                  variant="outlined"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </DialogContent>
              <Divider />
              <Typography style={{ padding: "16px" }} display={"flex"} justifyContent={"flex-end"} >
                <Button
                  variant="text"
                  color="primary"
                  onClick={closeDialog}
                >
                  Cancel
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  onClick={saveCommentAndStatus}
                >
                  Save
                </Button>
              </Typography>
            </Dialog> */}

          {/* <Button
              variant="contained"
              size="small"
              startIcon={<PersonAddIcon />}
              onClick={() => setDrawerOpen(true)}
              disabled={props.status === "Closed" || props.status === "Cancelled" || props.status === "Full"}
            >
              {" "}
              Add Candidate{" "}
            </Button> */}
          {/* <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<AutoAwesomeIcon />}
            // onClick={() => setQueryOptions({sortModel:[
            //   {
            //     field: "recommend",
            //     sort: "desc",
            //   }
            // ]})}
            onClick={() => {
              // Update the rerender key first
              setRerenderKey((prevKey) => prevKey + 1);
              // Then set the sort model
              setQueryOptions({
                sortModel: [
                  {
                    field: "recommend",
                    sort: "desc",
                  },
                ],
              });
            }}
          >
            Top Recommendations
          </Button> */}
        </Box>
      </Box>
      {/* <AddCandidateDrawer
          questionsrequired={props.questionrequired}
          isDrawerOpen={isDrawerOpen}
          setDrawerOpen={setDrawerOpen}
        /> */}
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "none",
            },
        }}
        keepNon
        rows={filteredApplicants}
        autoHeight
        columns={columns}
        key={rerenderKey}
        loading={showLoader}
        paginationMode="server"
        getRowId={(row) => row.id}
        onRowClick={HandleRowClick}
        //   checkboxSelection={checkboxSelected}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[18]}
        rowCount={totalCount}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={_debounce(onFilterChange, 500)}
        isRowSelectable={(params) => {
          if (
            params.row.isVerified === 0 ||
            params.row.status === "Withdrawn"
          ) {
            return false;
          }
          if (params.row.isVerified === 1) return true;
        }}
        onRowSelectionModelChange={handleSelection}
        disableRowSelectionOnClick
        className="data-grid-row-hover"
      />
      <CandidateDetails
        //   verifiedstatus={verifiedstatus}
        isOpen={isApplicanOpen}
        onClose={handleOnClose}
        setApplicanOpen={setApplicanOpen}
        setRerenderKey={setRerenderKey}
        //   opennew={opennew}
        //   setOpennew={setOpennew}
        selectedRowData={selectedRowData}
        //   setrefreshagain={setrefreshagain}
        //   refreshagain={refreshagain}
        candidatedrawerflag={candidatedrawerflag}
        Shortlisted={true}
        issearchnavbar={true}
        questionrequired={props.questionrequired}
        responsehide={props.questionrequired ? true : false}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={
            snackbarMessage === "Candidate Status Changed Successfully"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Applicants;
