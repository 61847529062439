import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import VerifiedIcon from "@mui/icons-material/Verified";
import CloseIcon from "@mui/icons-material/Close";


const DialogComponent = ({
    open,
    onClose,
    title,
    description,
    label,
    value,
    onChange,
    onConfirm,
    confirmText,
    confirmColor,
    icon: Icon,
    disableConfirm,
    showRequired = true, // New prop to control showing the asterisk
  }) => {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "1rem",
            padding: "1rem",
          },
        }}
      >
        <DialogTitle sx={{ paddingBottom: "0.5rem" }}>
          <Box display="flex" alignItems="center">
            <Icon color={confirmColor} sx={{ marginRight: 1 }} />
            {title}
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                marginLeft: "auto",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "1rem",
              fontWeight: 500,
            }}
          >
            {description}
          </Typography>
          <Box>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={value}
              onChange={onChange}
              label={
                <>
                  {label} {showRequired && <span style={{ color: "red" }}>*</span>}
                </>
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "0.75rem",
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "flex-end",
            gap: "0.5rem",
            marginTop: "1rem",
            padding: "0 1.5rem",
          }}
        >
          <Button
            onClick={onClose}
            color="secondary"
            variant="outlined"
            sx={{
              textTransform: "none",
              padding: "0.5rem 1.5rem",
              borderRadius: "0.75rem",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            color={confirmColor}
            variant="contained"
            disabled={disableConfirm}
            sx={{
              textTransform: "none",
              padding: "0.5rem 1.5rem",
              borderRadius: "0.75rem",
            }}
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default DialogComponent;