import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import "../custom.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const myTypes = {
  H: "Hire",
  C: "Contract",
  C2H: "Contract to Hire",
  F: "Freelance",
};

const statusColors = {
  Active: "primary",
  "On Hold": "default",
  Canceled: "error",
  Full: "warning",
  Closed: "error",
};

// const baseURL = process.env.REACT_APP_BASEURL;
const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "title",
    headerName: "Job Title",
    width: 300,
    renderCell: (params) => (
      <Typography
        variant="body2"
        title={params.row.title}
        textTransform={"capitalize"}
      >
        {params.row.title}
      </Typography>
    ),
  },
  {
    field: "type",
    headerName: "Job Type",
    width: 180,
    valueGetter: (params) => myTypes[params.value] || "N/A",
    renderCell: (params) => myTypes[params.row.type],
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    renderCell: (params) => (
      <Typography>
        <Chip
          size="small"
          label={params.row.status || "NA"}
          variant="outlined"
          color={statusColors[params.row.status] || "default"}
        />
      </Typography>
    ),
  },
  {
    align: "start",
    field: "date_created",
    headerName: "Created",
    width: 150,
    typeof: "date",
    valueGetter: ({ value }) =>
      value ? new Date(value).toLocaleDateString("en-US") : "N/A",
    renderCell: (params) =>
      new Date(params.row.date_created).toLocaleDateString(),
  },
  {
    field: "count",
    headerName: "Applications",
    width: 150,
    renderCell: (params) => (params.row.count ? params.row.count : 0),
  },

  {
    field: "open_count",
    headerName: "Open Applications",
    width: 150,
    renderCell: (params) => (params.row.open_count ? params.row.open_count : 0),
  },
  {
    field: "status_rejected_count",
    headerName: "Closed Applications",
    width: 150,
    renderCell: (params) =>
      params.row.status_rejected_count ? params.row.status_rejected_count : 0,
  },
  {
    field: "isApproved",
    headerName: "Is Approved",
    width: 150,
    renderCell: (params) => (
      <Typography>
        <Chip
          size="small"
          label={
            params.row.isApproved === 1
              ? "Approved"
              : "Pending Approval" || "NA"
          }
          variant="outlined"
          color={
            statusColors[params.row.isApproved === 1 ? "Active" : ""] || "error"
          }
        />
      </Typography>
    ),
  },
];

const ActiveJobs = ({ isActive, isSuccess }) => {
  const nav = useNavigate();
  const getToken = useSelector((state) => state.user.token);
  // const getRefreshToken = useSelector((state) => state.reducer.refreshToken);
  const [activejobs, setActiveJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter the activejobs based on the search query
  const filteredActiveJobs = activejobs.filter((job) =>
    job.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const company_id = localStorage.getItem("company_id");
    axios
      .post(
        `user/jobOrders`,
        { company_id, isActive },
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        localStorage.removeItem("jobdetailsTab");
        setActiveJobs(response.data.message);
        setIsLoading(false); // Set loading state to false when the response is received
        // console.log(response.data.message, "activejobs");
      })
      .catch((err) => {
        setIsLoading(false); // Set loading state to false in case of an error
      });
  }, [isActive, isSuccess]);

  // const allCookies = document.cookie;
  const HandleRowClick = (params, event) => {
    // console.log(params.row);
    const data = {
      id: params.row.id,
      title: params.row.title,
      type: params.row.type,
      status: params.row.status,
      date_created: params.row.date_created,
      count: params.row.count,
      openapplication: params.row.open_count,
      isApproved: params.row.isApproved,
    };
    nav(`/jobOrders/${params.row.id}`, {
      data,
      state: { isApproved: params.row.isApproved },
    });
  };

  return (
    <div>
      {isLoading ? ( // Render loading spinner when isLoading is true
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "30vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TextField
            variant="standard"
            placeholder="Search Job Title"
            id="standard-search"
            type="search"
            value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ marginBottom: "20px" }}
          />
          <DataGrid
            rows={filteredActiveJobs}
            columns={columns}
            autoHeight
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            // slots={{
            //   toolbar: GridToolbar,
            // }}
            onRowClick={HandleRowClick}
            className="data-grid-row-hover"
          />
        </>
      )}
    </div>
  );
};

export default ActiveJobs;
